class AmeMh extends HTMLElement {
    
    constructor() {
        super();
    }

    connectedCallback() {
        this.config();

        //added class on selector-wrapper
        document.querySelector(this.selectorWrapper).classList.add('mh2021Page');
    }

    config() {
        //css
        let style = document.createElement("style");
        style.textContent = this.style();
        this.appendChild(style);
        
        //appendo il markup
        this.insertAdjacentHTML('beforeend', this.template())

        // CLS Fix scroll
        this.fixCLSScroll();

        //js
        this.js();
    }

    template() {
        let template = `
            <div id="mh2021" class="mhInView">
                <div id="adv-gpt-masthead-leaderboard-container1" class="mh2021Strip adv strip"></div>
            </div>
            <div id="mh2021Fake"></div>
            <div id="bottomStrip2021">
                <div id="gpt_stripmobile"></div>
            </div>
        `;
        let thewom = `
            <div id="padding-strip" class="thewom-ticker">
                <span class="thewom-cat">
                </span>
            </div>
        `;
        if (window.location.hostname.includes("thewom")) {
            template = template + thewom;    
        }
        return template;
    }

    style() {

        let css = `
            :root {
                --altezzaMh2021: 33vw;
                --bgMh2021: #fff;
                --bgMh2021Page: #fff;
            }
            #mh2021 {
                height: var(--altezzaMh2021);
                width: 100vw;
                position: fixed;
                top: 0;
                left: 0;
                overflow: hidden;
            }
            #mh2021.mhForeground {
                z-index: 9999;
                pointer-events: none;
            }
            #mh2021Fake {
                height: var(--altezzaMh2021);
                width: 90vw;
                position: absolute;
                top: 0;
                left: 5vw;
                overflow: hidden;
                z-index: -100;
            }
            .mh2021Page {
                margin-top: 0;
                transition: margin 300ms ease-in-out;
                position: relative;
                z-index: 1;
                ${ (window.getComputedStyle(document.querySelector(this.selectorWrapper)).backgroundColor == "rgba(0, 0, 0, 0)") ? 'background:var(--bgMh2021Page);' : ''}
            }
            .mh2021Strip {
                width: 100vw;
                max-height: var(--altezzaMh2021);
                overflow: hidden;
                background: var(--bgMh2021);
                display: flex;
                justify-content: center;
                align-items: flex-start;
                pointer-events: all;
            }
            #bottomStrip2021 {
                width: 100vw;
                height: 100px;
                position: fixed;
                bottom: -100px;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: start;
                z-index: 99999;
                background-color: rgba(255,255,255,.8);
                transform: translateY(0);
                transition: transform 50ms ease-in-out;
            }
            #bottomStrip2021 > div {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            @media all and (min-width: 1000px) {
                :root {
                    --altezzaMh2021 : 250px;
                }
                .mh2021Strip {
                    background: ${(this.bgMh) ? this.bgMh : 'var(--bgMh2021)'};
                }
            }
        `;
        
        let thewom = `
            .thewom-ticker {
                font-family: 'Poppins', sans-serif;
                position: fixed;
                background-color: #110029;
                height: 0;
                line-height: 35px;
                width: 100vw;
                font-size:25px;
                overflow: hidden;
                white-space: nowrap;
                transform: translateY(-100%);
            }
            .thewom-ticker a {
                color: #FFF;
            }
            #mh2021.mhForeground ~ .thewom-ticker {
                z-index: 9999;
                top: var(--margin-top-adv);
                transform: translateY(0%);
            }
            #padding-strip .thewom-cat {
                width: 100%;
                height: 100%;
                display: block;
                background-image: url('https://dafne.sirio.stbm.it/utility-fe/prod/web-components/img/TheWom.svg?ver=1');
                background-position: center;
                background-size: auto 60%;
                background-repeat: repeat-x;
                opacity: .8;
            }
            #padding-strip .thewom-logo {
                max-height: 19px;
                width:auto;
                margin-bottom: -1px;
            }
            @media all and (min-width: 1000px) {
                .thewom-ticker {
                    height: 35px;
                }
            }
        `;
        
        if (window.location.hostname.includes("thewom")) {
            css =  css + thewom;    
        }

        return css;
    }

    fixCLSScroll() {
        setTimeout(function(){
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            console.log('Fix cls scroll',scrollTop);
            if(parseInt(scrollTop) <= 50) {
                window.scrollTo(0, 1);
            }
        },1500);
    }

    js() {
        window.googletag = window.googletag || { cmd: [] };

        if (this.disableStrip != "true" && !document.querySelector('.no-strip-mobile')) {
            let fallback = () => {
                setTimeout(() => {  
                    //fallback solo se non c'è una flyingpush che sta girando
                    if (document.getElementById('flyfirst-mobile-placement')) {
                        if(document.getElementById('flyfirst-mobile-placement').childNodes.length == 0) {
                            startStripMobile("fallback timer");
                        } else {
                            console.log('[strip mobile 2021] fallback fallita per presenza flyfirst')
                        }
                    } else {
                        startStripMobile("fallback timer");
                    }
                }, 20000);
            };

            if (typeof startStripMobile == "function") {
                postTcfReady(fallback);
            }
        }
    }

    get selectorWrapper() {
        return this.getAttribute('selector-wrapper');
    }
    
    get bgMh() {
        return this.getAttribute('bg-mh');
    }

    get disableStrip() {
        return this.getAttribute('disable-strip');
    }

    //strip_animation start
    static strip_animation() {

        let ameMhElement        = document.querySelector('ame-mh'),
            getEnableEvents     = ameMhElement.getAttribute('enable-events'),
            getDisableStrip     = ameMhElement.getAttribute('disable-strip'),
            getViewTimeLimit    = Number(ameMhElement.getAttribute('view-time-limit')),
            getFirstDelay       = Number(ameMhElement.getAttribute('first-delay'));
            
        let debug = 0,
            viewTimeLimit = (getViewTimeLimit && getViewTimeLimit != "") ? getViewTimeLimit : 5000,
            firstDelay = (getFirstDelay && getFirstDelay != "") ? getFirstDelay : 3000;

        let mh = document.getElementById("mh2021"),
            mhFake = document.getElementById("mh2021Fake"),
            page = document.querySelector('.mh2021Page'),
            strip = document.querySelector(".mh2021Strip"),
            paddingStrip = document.querySelector("#padding-strip"),
            flyFirstMobile = document.getElementById('flyfirst-mobile-placement'),
            
            mhIntersection,
            pageSpacedInTop = 0,
            stripH = strip.offsetHeight || 0;

        if (debug == 1) localStorage.setItem("mh2021Debug", 1);

        let log = (msg, val) => {
            if (localStorage.getItem("mh2021Debug"))
            console.log("[mh2021] - " + msg, val);
        };

        if (stripH == 0) {
            log("Strip ad altezza 0 -> return false");
            return false;
        }

        if (typeof window.stripanimationrun !== "undefined") {
            console.warn(
            "[mh2021] - ATTENZIONE! Strip animation richiamata più volte!"
            );
            return false;
        }
        window.stripanimationrun = 1;

        if (getEnableEvents == "true") {
            if (window.gtag) {
                gtag("event", "Start", {
                    event_category: "mh2021",
                    event_label: document.location.href,
                    non_interaction: true,
                });
            } else if (window.ga) {
                ga('send', 'event', "mh2021", "Start", document.location.href, {
                    nonInteraction: true
                });
            } else {}
        }

        let mh2021PageInTopView = (motivo) => {
            log("mh2021PageInTopView", motivo); //log del motivo per cui do margine superiore alla pagina
            observer.disconnect();
            let mhH = mh.firstElementChild.offsetHeight;
            if(mhH <= 50) mhH = 0; //fix per webview fb. Per qualche motivo gira la strip_animation sulla 3x1. Impediamo di mettere margine alla pagina se non c'è uno slot consistente e 50px dovrebbero essere safe.
            let padding = 0
            // se c'è il blocco di padding aggiungo anche quello all'altezza
            if (paddingStrip) padding = paddingStrip.offsetHeight;
            page.style.marginTop = mhH + padding + "px";
            pageSpacedInTop = 1;

            if (getEnableEvents == "true") {
                if (window.gtag) {
                    gtag("event", motivo, {
                        event_category: "mh2021",
                        event_label: document.location.href,
                        non_interaction: true,
                    });
                } else if (window.ga) {
                    ga('send', 'event', "mh2021", motivo, document.location.href, {
                        nonInteraction: true
                    });
                } else {}
            }
            
            /*document.body.removeEventListener('click',mh2021PageInTopView); //DA RIABILITARE IN CUI SI RIATTIVI L'INTERAZIONE UTENTE
            document.body.removeEventListener('keypress',mh2021PageInTopView);*/
        };

        //check iniziale
        setTimeout(() => {
            if (mhIntersection) {
                mh2021PageInTopView("In top view all'inizio");
            }
        }, firstDelay + 100);

        //fix
        setTimeout(() => {
            log("Fix inizio", performance.now());
            mh.classList.add("mhForeground");
            if (paddingStrip) {
                let mhH = mh.firstElementChild.offsetHeight;
                paddingStrip.style.setProperty('--margin-top-adv', mhH + 'px');
            }

        }, firstDelay + 400);

        //defix
        setTimeout(() => {
            log("Defix", performance.now());
            mh.classList.remove("mhForeground");

            if (getDisableStrip != "true" && !document.querySelector('.no-strip-mobile')) {
                if (flyFirstMobile) {
                    if(flyFirstMobile.childNodes.length == 0) {
                        setTimeout(() => {
                            if (typeof startStripMobile == "function") {
                                startStripMobile("mh fixed");
                            }
                        }, 1000);
                    }
                }
            }
        }, firstDelay + viewTimeLimit + 400);

        //callback observer
        let cbMh = (entries, observer) => {
            entries.forEach((entry) => {
            if (entry.isIntersecting) {
                if (pageSpacedInTop == 0 && mhIntersection == false) {
                    mh2021PageInTopView("Ritorno in top view");
                }
                mhIntersection = true;
            } else {
                mhIntersection = false;
            }
            });
        };

        //opzioni observer
        let optObs = {
            rootMargin: "0px",
            threshold: 1,
        };

        //dichiarazione observer
        let observer = new IntersectionObserver(cbMh, optObs);

        //start observer
        log("Start observer", "");
        observer.observe(mhFake);

        //a casa in caso di interazione utente [Per ora disabilitato]
        /*document.body.addEventListener('click',()=>{
            mh2021PageInTopView('Per user interaction click');
        });
        document.body.addEventListener('keypress',()=>{
            mh2021PageInTopView('Per user interaction key')
        });*/
    }

    //invocata da header mediamond, gestisce il posizionamento della strip mobile
    static callbackStripMobile() {
        googletag.pubads().addEventListener("slotOnload", function (event) {
            if (event.slot === stripmobile) {
                console.log("[strip mobile 2021] slotOnload");

                let bottomStrip2021 = document.getElementById("bottomStrip2021"),
                    stripH = document.getElementById("gpt_stripmobile").offsetHeight;

                console.log("[strip mobile 2021] height:", stripH);
                bottomStrip2021.style.transform = "translateY(" + stripH * -1 + "px)";
            }
        });
    }

}

customElements.define("ame-mh", AmeMh);